import { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { importImages } from "utils/importImages";

import Button from "components/Button/Button";
import Footer from "components/Footer/Footer";

import { fadeInOut, staggerItemsFade, itemFade } from "utils/animations";

const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

const HeroImage = ({ currentLanguage }) => (
  <img
    className="w-100 px-4 px-md-0"
    src={
      images[
        currentLanguage === "hr" ? "hero-visual-hr.png" : "hero-visual.png"
      ]
    }
  />
);

const HeroDescription = ({ t, gameData }) => (
  <div className="px-2">
    <h1 dangerouslySetInnerHTML={{ __html: t("headline") }} />
    <p className="lead text-white">{t("description")}</p>
  </div>
);

const HeroUsps = ({ t }) => (
  <div className="row mt-4">
    <div className="col-4 text-center">
      <img className="mb-2" src={images["hero-usp01.svg"]} />
      <p>{t("usp1")}</p>
    </div>
    <div className="col-4 text-center">
      <img className="mb-2" src={images["hero-usp02.svg"]} />
      <p>{t("usp2")}</p>
    </div>
    <div className="col-4 text-center">
      <img className="mb-2" src={images["hero-usp03.svg"]} />
      <p>{t("usp3")}</p>
    </div>
  </div>
);

const HeroButton = ({ clickSoundhandler, t, currentLanguage }) => (
  <div variants={itemFade}>
    {currentLanguage === "hr" && (
      <>
        <Button
          label={t("Igra je gotova")}
          className="btn btn-orange px-5 mt-3 pe-none"
        />
        <table class="customTable my-2 w-100">
          <thead>
            <tr>
              <th>Vrtić</th>
              <th>Postotak</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Dječji vrtić "Vrtuljak", općina Nuštar</td>
              <td>12 %</td>
            </tr>
            <tr>
              <td>Dječji vrtić “Čarobna šuma”, Špišić Bukovica</td>
              <td>14 %</td>
            </tr>
            <tr>
              <td>Dječji vrtić “DIDI”, Prikraj, Brckovljani</td>
              <td>12 %</td>
            </tr>
            <tr>
              <td>Dječji vrtić “IGRA”, PO Potočnica, Sigetec</td>
              <td>7 %</td>
            </tr>
            <tr>
              <td>Dječji vrtić “Maslačak”, Đurđevac</td>
              <td>28 %</td>
            </tr>
            <tr>
              <td>Dječji vrtić “Sušak”, PO Galeb, Rijeka</td>
              <td>26 %</td>
            </tr>
          </tbody>
        </table>
        <p>
          Hvala svima na sudjelovanju u JUPOL Izazovu! U nastavku donosimo
          finalni poredak glasova na dan 14.5.2023. u 23:59:59 h. Čestitamo
          pobjednicima!
        </p>
      </>
    )}
    <h3 className="mt-5">DOBITNICI NAGRADA</h3>

    <table className="customTable my-2 w-100">
      <tr>
        <td>IME I PREZIME</td>
        <td>NAGRADE</td>
      </tr>
      <tr>
        <td>LidijaČordarov</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>AleksandraMićić</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>IvanaJovašević</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>NadaDuricin</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>NadaBogdanovic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>JelenaGrubor-Petrušić</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Gordana Žeželj</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>OliveraBakalovic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Bojana Ivkovic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>JelenaPopovic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Tamara Rajačić</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Ivana Mihajlović</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>LjudmilaMijailović</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>VukBorza</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Mar6Bralović</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>SlobodanLoncar</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>BiljanaBuzas</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>FAHRUDINFeratovic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>MilanKrivokapić</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Nevena Milojevic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Nemanja Kaličanin</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>DanijelaHariba</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>AleksandraAleksandrov</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>LjiljanaDejanović</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>SaškaPavlović</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Vesna Aleksić</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>MarijanovicSmiljka</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>ObrenkaŽeželj</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>TamaraNedeljković</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>SvetlanaJovicic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Goran Djordjević</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>PetarSculac</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Dejan Todorović</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>ValentinaJagodin</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>MilicaStevancevic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>MarijaMitic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>IsidoraFrancuski</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>LazarJanicijevic</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>NatašaZarin</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>SNEŽANA STEVANČEV</td>
        <td>JUPOL Stolica za plažu</td>
      </tr>
      <tr>
        <td>Aleksandra Vrsajkovic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>NatašaNedeljković</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Jelena Marković</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>MarkoNikolić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>MišoStevović</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>NataškaŠtaub</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Nadica Vasiljevic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>JasminaRapajić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Marina BrankovicBrankovic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Aleksandra Jovanović</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>JelenaMarinković</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>KristinaMiletic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Jelena Galetin</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>SanjaBorščak</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>веснафемић</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>ДраганБојовић</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>SlavkaRadovanov</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>NovkaRistic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Sara Bulić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>AleksandraRistic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>DraganaRistić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Jelena Dašić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>IvonaIvanovic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>DanijelaGrujic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>RajkovicJovana</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>VesnaIvanović</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Andrea Bajčeta</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>MinaSerafimović</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>SlobodanSekulić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>ZoricaJovanović</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>ГорданаПавловић-Митић</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>BojanDjokovic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>Danijela Abić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>BojanaRačić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>AngelaBrasnjo</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>МаријаМиленковић</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>VukasinVeljkovic</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>ČEDOMIRSTEVANČEV</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>JelenaCiric</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>DejanKostić</td>
        <td>JUPOL Suncobran za plažu</td>
      </tr>
      <tr>
        <td>VojislavIlić</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MilanMiljevic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>TamaraMolnar</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>JelenaVuksanović</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Milosstosic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MilosBeronja</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>KatarinaMilosavljevic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>KatarinaBajai</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>KarolinaRiđički</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>JelenaJovanoviv</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MilicaKoraćević</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Sanja Anicic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>TamaraKoso</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>SandraLojanica</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Saša Vecko</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Tatjana Radojević</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Dušica Mikić</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>IvanaVićentijević</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>AnaStanomiroviv</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MirzetaHadžibrahimović</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Milkica Drakulić</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MilaMilenkovic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>AnamarijaKlarić</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Nikola Kamdžijaš</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MajaStevanović</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>GordanaSavanovic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>IlijaIlic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Marija Jovanovic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MarinaRadosavljev</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>JovanaMarkovic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>JelenaJovanović</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>ZlatiborRadovanovic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>Mirko Matić</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>AnaSaku</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>TanjaLovrić</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>JovanaVeljković</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>VESNASTOJANOVIĆ</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>DuškaKuzmanović</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>TanjaSadiki Sabo</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>JelenaRadivojevic</td>
        <td>JUPOL Peškir za plažu</td>
      </tr>
      <tr>
        <td>MarijaNastic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Sanjamilosavljevic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>IvanTodoručin</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>AnaDrazenovic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>JelenaKrcmar</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Jovana Rakić</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>NelaZivojinovic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Ivana Vitorović</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>VanesaGaćina</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>MilenaCerović</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>JelenaJovic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Sidonija Bakić</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>MarijaKožović</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>AnđelaJovanović</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>GoranaStankov</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>LjubicaCiric</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>BiljanaPetrović</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>LjiljanaJaksic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>MilenaStević</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>AnaVelickovic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>BranislavŠoškić</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>NenadLukic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>IvanTodorovic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>LjubicaAndzic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>LukaPivljaković</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Dragana Krtinic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Andrea Sečei Novak</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>MilicaIvkovic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>KosicaPjevač</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Sandra Smiljkovic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>SvetlanaKolobaric</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>MarijaDjusic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>MirjanaMančić</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>SanjaStanišić</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>ZoricaJeremić</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>MilicaMladenović</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>Tatjana Obodjan</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>NikolaDjurović</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>BobanVasiljevic</td>
        <td>JUPOL Lopta</td>
      </tr>
      <tr>
        <td>JelicaDobric</td>
        <td>JUPOL Lopta</td>
      </tr>
    </table>

    <Link className="d-none" to="/games" onClick={clickSoundhandler}>
      <Button label={t("Start")} className="btn btn-orange px-5 mt-3" />
    </Link>
  </div>
);

const Home = ({ gameData, play, stop, currentLanguage }) => {
  const { t } = useTranslation();

  const clickSoundhandler = () => {
    stop();
    play();
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="home pt-6 vh-100 d-flex flex-column">
      <motion.div
        className="d-flex flex-column h-100"
        variants={staggerItemsFade}
        initial="hidden"
        animate="show">
        <div className="flex-grow-1">
          <div className="hero pt-2 pt-md-0 flex-grow-1">
            <div className="container text-center text-md-start">
              <div className="row d-flex align-items-center">
                <motion.div
                  variants={itemFade}
                  className="col-md-6 order-2 order-md-1">
                  <HeroImage currentLanguage={currentLanguage} />
                  <div className="d-block d-md-none">
                    <HeroUsps t={t} />
                    <HeroButton
                      t={t}
                      clickSoundhandler={clickSoundhandler}
                      currentLanguage={currentLanguage}
                    />
                  </div>
                </motion.div>
                <motion.div
                  variants={itemFade}
                  className="col-md-6 order-1 order-md-2">
                  <HeroDescription t={t} gameData={gameData} />
                  <div className="d-none d-md-block">
                    <HeroUsps t={t} />
                    <HeroButton t={t} clickSoundhandler={clickSoundhandler} />
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <div
            className={`${
              currentLanguage !== "hr" && "prizes"
            }  position-relative mt-5 pt-5 pt-md-6 pb-4 `}>
            <img
              className="position-absolute d-none d-md-block"
              style={{ right: "10%", top: "-30px" }}
              src={images["elm-marjetica.svg"]}
            />
            <motion.div
              variants={itemFade}
              className="container d-flex justify-content-center">
              <div className="row d-flex  align-items-center">
                {currentLanguage === "rs" && (
                  <>
                    <div className="col-12 col-md-auto text-center text-md-left mb-5 mb-sm-0">
                      <h2 className="text-orange mb-0 me-md-5">Nagrade</h2>
                    </div>

                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes1.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">40x</h3>
                        <p className="text-orange">{t("prize1")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes4.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">40x</h3>
                        <p className="text-orange">{t("prize2")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes2.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">40x</h3>
                        <p className="text-orange">{t("prize3")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes3.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">40x</h3>
                        <p className="text-orange">{t("prize4")}</p>
                      </div>
                    </div>
                  </>
                )}
                {currentLanguage === "sl" && (
                  <>
                    <div className="col-12 col-md-auto text-center text-md-left mb-5 mb-sm-0">
                      <h2 className="text-orange mb-0 me-md-5">Nagrade</h2>
                    </div>

                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes1.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">15x</h3>
                        <p className="text-orange">{t("prize1")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes2.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">15x</h3>
                        <p className="text-orange">{t("prize2")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes3.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">15x</h3>
                        <p className="text-orange">{t("prize3")}</p>
                      </div>
                    </div>
                  </>
                )}
                {currentLanguage === "ba" && (
                  <>
                    <div className="col-12 col-md-auto text-center text-md-left mb-5 mb-sm-0">
                      <h2 className="text-orange mb-0 me-md-5">Nagrade</h2>
                    </div>

                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes1.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">50x</h3>
                        <p className="text-orange">{t("prize1")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes4.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">50x</h3>
                        <p className="text-orange">{t("prize2")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes2.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">50x</h3>
                        <p className="text-orange">{t("prize3")}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                      <img className="mb-2 me-1" src={images["prizes3.png"]} />
                      <div>
                        <h3 className="text-orange mb-0">50x</h3>
                        <p className="text-orange">{t("prize4")}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </motion.div>
          </div>
        </div>

        <Footer currentLanguage={currentLanguage} />
      </motion.div>
    </motion.div>
  );
};

export default Home;
